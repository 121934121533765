import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChildren,
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';

import { ArticleHead, NewsblocDisplayMode, NewsblocSlice, SubThematicsData } from '@repo/shared';
import { SliceWithData } from '../../../../typings';
import { RouterService } from '../../../services/router.service';
import { seeMoreAnimation } from '../../../common/constants/animations';
import { ResponsiveService } from '../../../services/responsive.service';
import { Params } from '@angular/router';
import { SLICE_TAGGING_NAME } from '../../../common/constants/tagging';
import { FilterBlockComponent } from '../../../ui/molecules/filter-block/filter-block';
import { DOCUMENT } from '@angular/common';
import { SlicesNewsBlocService } from '../../../services/slices-newsbloc.service';
import { TagCommanderService } from '../../../services/tag-commander.service';

const STANDARD_INITIAL_ARTICLES_COUNT = 3;
const ADVANCED_INITIAL_ARTICLES_COUNT = 6;
const MORE_ARTICLES_COUNT = 6;
const CLICKED_SUBTHEMATIC_KEY = 1;
const CLICKED_DATE_KEY = 2;

@Component({
  selector: 'slice-newsbloc',
  template: `
    <div *ngIf="data && data.articles && data.articles.length" class="content">
      <cb-mini-title *ngIf="data.newsTitle" [title]="data.newsTitle" [withFrame]="!isFirstSlice"></cb-mini-title>
      <div class="filters-blocks" *ngIf="showFilterBlock()">
        <filter-block
          [filters]="subThematicsFilters"
          [filterLabel]="'Filtrer par sous-thématique'"
          [filterNumber]="1"
          (filtersClickedEvent)="onFilterClicked($event)"
        >
        </filter-block>
        <filter-block
          [filters]="dateFilters"
          [filterLabel]="'Filtrer par année'"
          [filterNumber]="2"
          (filtersClickedEvent)="onFilterClicked($event)"
        >
        </filter-block>
      </div>
      <button class="resetButton" *ngIf="isResetButtonVisible()" (click)="this.resetFilters()">
        Supprimer les filtres
      </button>
      <div class="wrapper">
        <cb-article-thumbnail
          *ngFor="let article of articlesShown; let i = index"
          [article]="article"
          [@enterIn]="getArticleShowAnimationState(i)"
        >
        </cb-article-thumbnail>
      </div>
      <div class="bloc-button" *ngIf="data.seeMoreNewsLabel && hasMoreArticle">
        <button
          class="a11y-focus btn--see-more"
          analyticsOn
          [analyticsLabel]="getAnalyticsLabel()"
          (click)="showMore()"
        >
          <span class="label">{{ data.seeMoreNewsLabel }}</span>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./slice-newsbloc.component.scss'],
  animations: [seeMoreAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceNewsblocComponent implements SliceWithData<NewsblocSlice>, OnInit, OnDestroy {
  lastCursor: number;
  numberOfArticles = 0;
  subscription$: Subscription;
  isFiltered = false;

  articlesShown: ArticleHead[] = [];
  filteredArticles: ArticleHead[] = [];
  subThematicsFilters: string[];
  dateFilters: string[];
  selectedSubThematics: string[];
  selectedSubthematicsIds: string[] = [];
  selectedDates: string[] = [];
  previousYearList: string[] = [];
  previousSubThematicList: string[] = [];

  @Input() data: NewsblocSlice;
  @Input() isFirstSlice: boolean;
  @Input() sliceIndex: number;
  @Input() cursor = STANDARD_INITIAL_ARTICLES_COUNT;

  @ViewChildren(FilterBlockComponent) filterBlocks: FilterBlockComponent[];

  get articles(): ArticleHead[] {
    return (this.data && this.data.articles) || [];
  }

  get subThematic(): SubThematicsData[] {
    return (this.data && this.data.subThematics) || [];
  }

  get hasMoreArticle(): boolean {
    return this.cursor < (this.isFiltered ? this.filteredArticles.length : this.articles.length);
  }

  constructor(
    private readonly tagCommanderService: TagCommanderService,
    private routerService: RouterService,
    private responsiveService: ResponsiveService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: Document,
    private slicesNewsBlocService: SlicesNewsBlocService,
  ) {}

  ngOnInit(): void {
    this.subscription$ = combineLatest([
      this.responsiveService.isDesktopMatched(),
      this.routerService.getActivatedRoute().queryParams,
    ]).subscribe(([desktop, queryParams]: [boolean, Params]) => {
      this.computeCursorAndRefreshArticles(desktop, queryParams);
      // envoyer le nombtre d'articles
      this.sendNumberOfArticles(this.isFiltered, this.filteredArticles, this.articles);
    });
    this.extractFilters();
  }

  getArticleShowAnimationState(index: number): 'visible' | 'new' {
    return index < this.lastCursor ? 'visible' : 'new';
  }

  computeCursorAndRefreshArticles(desktop: boolean = false, queryParams: Params = {}): void {
    const initialCursor =
      desktop && this.data.displayMode === NewsblocDisplayMode.ADVANCED
        ? ADVANCED_INITIAL_ARTICLES_COUNT
        : STANDARD_INITIAL_ARTICLES_COUNT;

    const cursor = +queryParams[this.getCursorQueryParamKey()] || initialCursor;

    this.lastCursor = this.cursor;
    this.cursor = cursor;
    this.getSliceArticlesFiltred(false);
  }

  getSliceArticlesFiltred(isFiltered: boolean): void {
    this.articlesShown = isFiltered ? this.filteredArticles.slice(0, this.cursor) : this.articles.slice(0, this.cursor);
    this.changeDetectorRef.markForCheck();
  }

  extractFilters(): void {
    this.subThematicsFilters = this.subThematic.map(sub => sub.subThematicLibele).slice(0, 6) as string[];
    this.dateFilters = this.getFiltersDate();
    this.changeDetectorRef.markForCheck();
  }

  getCursorQueryParamKey(): string {
    return `news-${this.sliceIndex || 0}`;
  }

  setFilterQueryParam(param: Params): void {
    if (param) {
      this.routerService.replaceQueryParams(param);
    }
  }

  getAnalyticsLabel(): string {
    return `${SLICE_TAGGING_NAME[this.data.type]}_${this.data.seeMoreNewsLabel}`;
  }

  getFiltersDate(): string[] {
    const currentYear = new Date().getFullYear();
    const yearMinus1 = currentYear - 1;
    const yearMinus2 = currentYear - 2;
    return [currentYear.toString(), yearMinus1.toString(), yearMinus2.toString()];
  }

  getFilteredArticles(subthematicFilters: string[], dateFilters: string[], showMoreParams?: number): ArticleHead[] {
    let filteredArticlesHead!: ArticleHead[];
    if (subthematicFilters.length > 0) {
      this.isFiltered = true;
      filteredArticlesHead = this.articles.filter(article =>
        subthematicFilters.includes(article.subThematic as string),
      );
    }
    if (dateFilters.length > 0) {
      this.isFiltered = true;
      filteredArticlesHead = filteredArticlesHead
        ? filteredArticlesHead.filter(article => dateFilters.includes(article.publishOn?.slice(0, 4) as string))
        : this.articles.filter(article => dateFilters.includes(article.publishOn?.slice(0, 4) as string));
    }
    showMoreParams = showMoreParams && showMoreParams > 6 ? showMoreParams : 0;
    this.setFiltersQueryParams(subthematicFilters, dateFilters, showMoreParams);
    return filteredArticlesHead;
  }

  onFilterClicked(eventData: any): void {
    this.isFiltered = false;
    if (eventData) {
      eventData.forEach((val, key) => {
        if (key === CLICKED_SUBTHEMATIC_KEY) {
          // tslint:disable-next-line: max-line-length
          const clickedSubThematic =
            this.previousSubThematicList.length < val.length
              ? val.filter(value => !this.previousSubThematicList.includes(value))
              : this.previousSubThematicList.filter(value => !val.includes(value));
          this.previousSubThematicList = val;
          this.tagCommanderService.trackClickActionEvent(clickedSubThematic[0]);
          this.selectedSubThematics = val;
          this.selectedSubthematicsIds = (this.data?.subThematics as SubThematicsData[])
            .filter(data => this.selectedSubThematics.includes(data.subThematicLibele as string))
            .map(selectedData => selectedData.thematicId);
        } else if (key === CLICKED_DATE_KEY) {
          // tslint:disable-next-line: max-line-length
          const clickedYear =
            this.previousYearList.length < val.length
              ? val.filter(value => !this.previousYearList.includes(value))
              : this.previousYearList.filter(value => !val.includes(value));
          this.previousYearList = val;
          this.tagCommanderService.trackClickActionEvent(clickedYear[0]);
          this.selectedDates = val;
        }
        this.filteredArticles = this.getFilteredArticles(this.selectedSubthematicsIds, this.selectedDates, this.cursor);
        this.sendNumberOfArticles(this.isFiltered, this.filteredArticles, this.articles);
        this.getSliceArticlesFiltred(this.isFiltered);
      });
    }
  }

  setFiltersQueryParams(subthematicFilters: string[], dateFilters: string[], showMoreParam?: number): void {
    if (subthematicFilters && dateFilters) {
      this.setFilterQueryParam({
        [this.getCursorQueryParamKey()]: showMoreParam ? showMoreParam : null,
        ['subthematic']: this.selectedSubthematicsIds.length ? this.selectedSubthematicsIds : null,
        ['year']: this.selectedDates.length ? this.selectedDates : null,
      });
    }
  }

  showFilterBlock(): boolean {
    return !!this.data?.subThematics && this.data.subThematics.length > 1 && this.document.body.offsetWidth > 768;
  }

  showMore(): void {
    this.lastCursor = this.cursor;
    this.cursor += MORE_ARTICLES_COUNT;
    if (this.selectedSubthematicsIds?.length || this.selectedDates?.length) {
      this.getSliceArticlesFiltred(true);
    } else {
      this.routerService.replaceQueryParam(this.getCursorQueryParamKey(), this.cursor);
      this.getSliceArticlesFiltred(false);
    }
    this.sendNumberOfArticles(this.isFiltered, this.filteredArticles, this.articles);
  }

  resetFilters(): void {
    let showMoreParam: number;
    this.isFiltered = false;
    this.selectedSubThematics = [];
    this.selectedSubthematicsIds = [];
    this.selectedDates = [];
    this.previousSubThematicList = [];
    this.previousYearList = [];
    this.filterBlocks.forEach(fb => fb.resetButtonFilters());
    // Parametrage url réinitialisation filtre
    // eslint-disable-next-line prefer-const
    showMoreParam = this.cursor > 6 ? this.cursor : 0;
    this.setFilterQueryParam({
      [this.getCursorQueryParamKey()]: showMoreParam ? showMoreParam : null,
      ['subthematic']: this.selectedSubthematicsIds.length ? this.selectedSubthematicsIds : null,
      ['year']: this.selectedDates.length ? this.selectedDates : null,
    });
    this.getSliceArticlesFiltred(false);
    this.sendNumberOfArticles(this.isFiltered, this.filteredArticles, this.articles);
    this.tagCommanderService.trackClickActionEvent('Supprimer les filtres');
  }

  /**
   * Method to get number of articles and send the value to the component simple page ( N articles)
   *
   * @param isFiltered filtred or not
   * @param filteredArticles articles filtred
   * @param articles total of articles
   */
  sendNumberOfArticles(isFiltered: boolean, filteredArticles: ArticleHead[], articles: ArticleHead[]): void {
    this.numberOfArticles = isFiltered ? filteredArticles.length : articles.length;
    this.slicesNewsBlocService.updateValueItem(this.numberOfArticles);
  }
  /**
   * Method return bool to show button reset
   */
  isResetButtonVisible(): boolean {
    return this.isFiltered;
  }

  ngOnDestroy(): void {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }
}
