<form class="form" [formGroup]="regForm.get('request')" novalidate>
  <div id="request">
    <fieldset>
      <legend class="date-title">Date de résiliation souhaitée</legend>
      <p>Sous réserve des stipulations contractuelles et des dispositions légales en vigueur</p>
      <div formFieldControl class="form-field-control--mega-size">
        <form-field-select
          formFieldSelect
          [options]="deadlineOptions"
          [formControlName]="'deadline'"
          [selectError]="selectError"
          [sendValue]="'string'"
        ></form-field-select>
        <form-field-error></form-field-error>
      </div>
    </fieldset>

    <ng-container *ngIf="checkSelectedDeadline()">
      <div formFieldControl class="form-field-control--half-size">
        <datepicker formFieldDatepicker formControlName="resiliationDate" timeDirection="after"></datepicker>
        <form-field-error></form-field-error>
      </div>
    </ng-container>

    <p class="mandatory-field-text">*Champs obligatoires</p>
    <div class="button-wrapper">
      <cb-cta-btn-link
        class="previous"
        (click)="previous()"
        [button]="{ label: 'Précédent', type: 'multi-step-form-previous' }"
        [buttonWithoutLink]="true"
        [sliceType]="'resiliation_form'"
      ></cb-cta-btn-link>
      <cb-cta-btn-link
        class="next"
        (click)="checkRecaptcha()"
        [button]="{ label: 'Suivant', type: 'multi-step-form-next' }"
        [buttonWithoutLink]="true"
        [sliceType]="'resiliation_form'"
        [disabled]="_buttonDisabled"
        [analyticsLabel]="tagName"
        [analyticsSubPath]="tagSubPath"
        analyticsOn
      ></cb-cta-btn-link>
      <form-field-error
        class="post-error"
        [class.shown]="_hasPostError"
        message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
      >
      </form-field-error>
    </div>
    <p class="recaptcha-mentions">
      Ce site est protégé par reCAPTCHA. Les
      <a href="https://policies.google.com/privacy">règles de confidentialité</a>
      et les
      <a href="https://policies.google.com/terms">conditions d'utilisation</a>
      de Google s'appliquent.
    </p>
  </div>
</form>
