<form class="form" [formGroup]="regForm.get('request')" novalidate>
  <div id="request">
    <h2>Message</h2>
    <div formFieldControl class="form-field-control--mega-size">
      <fieldset>
        <legend>Votre demande concerne : *</legend>

        <form-field-select
          formFieldSelect
          [options]="subjectsOptions"
          [formControlName]="'subject'"
          [selectError]="selectError"
          [placeholder]="data.title_placeholder_message"
        ></form-field-select>

        <form-field-error></form-field-error>
      </fieldset>
    </div>
    <div formFieldControl>
      <textarea
        formControlName="message"
        placeholder="Ecrivez votre message ici..."
        #messageField="formFieldInput"
        formFieldInput
      ></textarea>
      <div formFieldHint>
        {{ messageField.charCountLabel }}
      </div>
      <form-field-error></form-field-error>
    </div>

    <h2>Pièces jointes</h2>
    <p>
      {{ data.under_jointpiece }}
    </p>
    <div formFieldControl class="form-field-control-file-upload">
      <file-upload-form formControlName="files" fileUploadForm></file-upload-form>
      <form-field-error></form-field-error>
    </div>

    <p class="mandatory-field-text">*Champs obligatoires</p>
    <p>
      {{ data.notice_rgpd }}
    </p>
    <div class="button-wrapper">
      <cb-cta-btn-link
        class="previous"
        (click)="previous()"
        [button]="{ label: 'Précédent', type: 'multi-step-form-previous' }"
        [buttonWithoutLink]="true"
        [sliceType]="'reclamation_form'"
        [disabled]="_buttonDisabled"
      ></cb-cta-btn-link>
      <cb-cta-btn-link
        class="next"
        (click)="checkRecaptcha()"
        [button]="{ label: 'Envoyer', type: 'multi-step-form-next' }"
        [buttonWithoutLink]="true"
        [sliceType]="'reclamation_form'"
        [disabled]="_buttonDisabled"
      ></cb-cta-btn-link>
      <form-field-error
        class="post-error"
        [class.shown]="_hasPostError"
        message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
      >
      </form-field-error>
    </div>
    <p class="recaptcha-mentions">
      Ce site est protégé par reCAPTCHA. Les
      <a href="https://policies.google.com/privacy">règles de confidentialité</a>
      et les
      <a href="https://policies.google.com/terms">conditions d'utilisation</a>
      de Google s'appliquent.
    </p>
  </div>
</form>
