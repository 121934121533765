import {
  Component,
  Input,
  ElementRef,
  ChangeDetectorRef,
  OnInit,
  Inject,
  ViewChild,
  forwardRef,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../../tokens';
import { LOAN_INSURANCE_IDENTITY_FORM, OPTIONS_LOANS_QUANTITIES } from '@repo/shared/form-schemas/loan-insurance';
import { DOMService } from '../../../../../services/dom.service';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SliceFormsService } from '../../slice-forms.service';
import { AgenciesService } from '../../../../../services/agencies.service';
import { AgenciesData, AgenciesResult, Agency } from '../../../../../services/agencies.type';
import { AgencyFormComponent } from '../../../slice-agency/agency-form/agency-form.component';

const IC_INFO = '/assets/images/ico-info-dark-blue.svg';

@Component({
  selector: 'loan-insurance-identity',
  template: `
    <form class="form form-width" [formGroup]="regForm.get('identity')" novalidate>
      <div id="identity">
        <h2>Information</h2>
        <h3>Identité</h3>
        <div formFieldControl>
          <fieldset>
            <legend>Civilité*</legend>
            <div formFieldRadioGroup class="form-field-control--half-size">
              <form-field-radio name="civilTitle" formControlName="civilTitle" value="Mme">
                <span class="ic ic-woman civil-icon"></span>
                Madame
                <div class="check-icon">
                  <span class="ic-check"></span>
                </div>
              </form-field-radio>
              <form-field-radio
                name="civilTitle"
                formControlName="civilTitle"
                value="M"
                class="form-field-radio--right"
              >
                <span class="ic ic-man civil-icon"></span>
                Monsieur
                <div class="check-icon">
                  <span class="ic-check"></span>
                </div>
              </form-field-radio>
            </div>
            <form-field-error></form-field-error>
          </fieldset>
        </div>
        <div formFieldControl class="form-field-control--half-size">
          <label for="lastName"> Nom* </label>
          <input formControlName="lastName" name="lastName" type="text" placeholder="Dupont" formFieldInput />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size form-field-control--right">
          <label for="firstName"> Prénom* </label>
          <input placeholder="Antoine" type="text" name="firstName" formControlName="firstName" formFieldInput />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size">
          <label for="email"> E-mail* </label>
          <input formControlName="email" type="email" name="email" formFieldInput placeholder="exemple@mail.com" />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size form-field-control--right">
          <label for="phone"> Numéro de téléphone* </label>
          <input
            formControlName="phone"
            type="text"
            name="phone"
            formFieldInput
            inputmode="numeric"
            pattern="[0-9]*"
            mask="00 00 00 00 00"
            placeholder="01 23 45 67 89"
          />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size">
          <label for="birthDate"> Date de naissance* </label>
          <datepicker formFieldDatepicker name="birthDate" formControlName="birthDate"></datepicker>
          <form-field-error></form-field-error>
        </div>
        <h3>Adresse</h3>
        <div formFieldControl class="form-field-control--half-size">
          <label for="street"> Rue* </label>
          <input placeholder="25 rue Saint Louis" type="text" name="street" formControlName="street" formFieldInput />
        </div>
        <div formFieldControl class="form-field-control--half-size form-field-control--right">
          <label for="city"> Ville* </label>
          <input placeholder="Paris" type="text" name="city" formControlName="city" formFieldInput />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size">
          <label for="postalCode"> Code postal* </label>
          <input
            placeholder="75011"
            formControlName="postalCode"
            formFieldInput
            type="text"
            name="postalCode"
            inputmode="numeric"
            pattern="[0-9]*"
            mask="00000"
          />
          <form-field-error></form-field-error>
        </div>

        <h2>Agence LCL</h2>
        <div class="agencies-search-box">
          <div>
            <div formFieldControl class="form-field-control--half-size">
              <app-agency-form
                (optionSelected)="searchNearAgencies($event)"
                [isChildForm]="true"
                [displayNoAgencyMessage]="this.showSearchAgenciesResults && this.agencies.length === 0"
              >
              </app-agency-form>
            </div>
          </div>
          <div
            *ngIf="showSearchAgenciesResults && agencies.length > 0 && !selectedAgency"
            formFieldControl
            class="agencies-result-container"
          >
            <label>Sélectionnez une agence dans la liste de résultats.</label>
            <div *ngIf="displayMandatoryAgencyMessage" class="agency-mandatory">Sélection obligatoire.</div>
            <div *ngIf="agencies.length === 0" class="agency-count">
              Aucune agence LCL trouvée. Veuillez refaire une recherche
            </div>
            <div class="agencies-container">
              <div
                *ngFor="let agency of agencies; let agencyIndex = index; let odd = odd"
                class="agency"
                [ngClass]="{ 'agency-odd': odd }"
                (click)="updateAgencyData(agency)"
              >
                <div class="agency-info-name">{{ agency.name }}</div>
                <div>{{ agency.address }} - {{ agency.postalCode }} {{ agency.town }}</div>
              </div>
            </div>
          </div>
          <div formFieldControl class="hide-item">
            <label
              >Agence
              <input formControlName="agencyRef" formFieldInput type="text" />
            </label>
          </div>
        </div>

        <h2>Référence compte</h2>
        <div formFieldControl class="form-field-control--half-size">
          <label>
            Indicatif*
            <input
              placeholder="01234"
              formControlName="agencyID"
              formFieldInput
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              mask="00000"
            />
          </label>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size form-field-control--right">
          <label for="accountNumber"> Compte* </label>
          <ui-tooltip
            message="Vous pouvez retrouver votre indicatif ainsi que votre numéro de compte courant dans votre relevé bancaire LCL                 (Ces informations sont précisées dans les                 « Références Bancaires Nationales » au sein de l’encart « Relevé d’Identité Bancaire »)"
            position="right"
          >
            <span>
              <img [attr.src]="icInfo" height="18" width="18" />
            </span>
          </ui-tooltip>
          <input
            placeholder="012345 + clé"
            name="accountNumber"
            formControlName="accountNumber"
            formFieldInput
            type="text"
          />
          <form-field-error></form-field-error>
        </div>

        <h2>Prêt(s)</h2>
        <div formFieldControl class="form-field-control--half-size">
          <fieldset>
            <legend>Nombre de prêts concernés*</legend>

            <form-field-select
              formFieldSelect
              [options]="loansQuantitiesOptions"
              [formControlName]="'loansQuantity'"
              [selectError]="_selectError"
              (selectionChange)="handleLoanQuantitySelect()"
            >
            </form-field-select>
            <form-field-error></form-field-error>
          </fieldset>
        </div>
        <div *ngIf="loanQuantitiesSelected">
          <div *ngFor="let item of [].constructor(loanQuantities); let i = index">
            <div formFieldControl class="form-field-control--half-size">
              <label for="loanNumber"> Numéro de prêt* </label>
              <ui-tooltip
                message="Vous pouvez retrouver votre numéro de prêt dans votre contrat de prêt immobilier."
                position="right"
              >
                <span>
                  <img [attr.src]="icInfo" height="18" width="18" />
                </span>
              </ui-tooltip>
              <input
                placeholder="16 caractères"
                name="loanNumber"
                formControlName="loanNumber{{ i + 1 }}"
                formFieldInput
                type="text"
              />
              <form-field-error></form-field-error>
            </div>
          </div>
        </div>

        <p class="mandatory-field-text">*Champs obligatoires</p>
        <p>
          La politique de protection des données personnelles, incluse dans les Dispositions Générales de Banque est
          disponible sur la page d'accueil du site.
        </p>
      </div>
      <div class="button-wrapper">
        <cb-cta-btn-link
          class="button"
          (click)="onSubmit()"
          [button]="{ label: 'Suivant', type: 'multi-step-form-next' }"
          [buttonWithoutLink]="true"
          [sliceType]="'loan_insurance_form'"
          [disabled]="_buttonDisabled"
        >
        </cb-cta-btn-link>
      </div>
    </form>
  `,
  styleUrls: ['../../slice-forms.scss', './loan-insurance-identity.component.scss'],
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: LOAN_INSURANCE_IDENTITY_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => LoanInsuranceIdentityFormComponent),
    },
  ],
})
export class LoanInsuranceIdentityFormComponent
  extends AbstractFormComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input() regForm: FormGroup;

  readonly loansQuantitiesOptions = OPTIONS_LOANS_QUANTITIES;
  _buttonDisabled = false;
  _hasPostError = false;
  _selectError = false;

  icInfo: string = IC_INFO;
  loanQuantitiesSelected = false;
  loanQuantities: number | null = null;
  agencies: Agency[] = [];
  showSearchAgenciesResults = false;
  @ViewChild(AgencyFormComponent) agencyFormComponent: AgencyFormComponent;
  selectedAgency: Agency;
  displayMandatoryAgencyMessage: boolean;
  private agencyQuerySubscription: Subscription;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _loanInsuranceFormValidationSchema: JSONSchema7,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
    private readonly agenciesService: AgenciesService,
    private readonly sliceFormsService: SliceFormsService,
  ) {
    super(_loanInsuranceFormValidationSchema, elementRef, domService, changeDetectorRef);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const identityGroup = this.regForm.get('identity') as FormGroup;
    const agencyQueryControl = this.agencyFormComponent.agencyForm.get('query') as FormControl;
    identityGroup.addControl('agencyQuery', this.agencyFormComponent.agencyForm.get('query') as AbstractControl);
    agencyQueryControl.setParent(identityGroup);

    agencyQueryControl.setValidators([
      this.sliceFormsService._getFieldValidator(
        (LOAN_INSURANCE_IDENTITY_FORM.properties as any)['agencyQuery'] as JSONSchema7,
        'agencyQuery',
      ),
    ]);
    agencyQueryControl.updateValueAndValidity();

    this.agencyQuerySubscription = this.initAgencyQuerySubscription();
  }

  ngOnDestroy(): void {
    if (this.agencyQuerySubscription) {
      this.agencyQuerySubscription.unsubscribe();
    }
  }

  searchNearAgencies(agenciesData: AgenciesData): void {
    this.regForm.get('identity')?.patchValue({ agencyQuery: this.agencyFormComponent.agencyForm.get('query')?.value });

    // resetting selected agency value
    this.regForm.get('identity')?.patchValue({ agencyRef: '' });
    this.selectedAgency = (null as unknown) as Agency;

    this.agenciesService.searchNearAgencies(agenciesData.agenciesFilter).subscribe(agenciesResult => {
      // RSI-1623 Hide Profesionals Agencies
      this.agencies = this.getOrderedAgenciesFromResult(agenciesResult).filter(agency => agency.agencyType !== 'PRO');
      this.showSearchAgenciesResults = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  initAgencyQuerySubscription(): Subscription {
    return this.agencyFormComponent.agencyForm.get('query')?.valueChanges.subscribe(value => {
      if (!value) {
        this.showSearchAgenciesResults = false;
      }
    }) as Subscription;
  }

  private getOrderedAgenciesFromResult(agenciesResult: AgenciesResult): Agency[] {
    if (!agenciesResult || !agenciesResult.agencies) {
      return [];
    }
    return agenciesResult.agencies
      .sort((a, b) => (a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : 0))
      .slice(0, 10);
  }

  updateAgencyData(agency: Agency): void {
    this.selectedAgency = agency;
    this.displayMandatoryAgencyMessage = false;
    this.regForm.get('identity')?.patchValue({ agencyRef: ('0' + agency.id).slice(-5) });
    this.agencyFormComponent.agencyForm.patchValue({ query: agency.name.toUpperCase() });
  }

  handleLoanQuantitySelect(): void {
    const newLoanQuantity = this.regForm.get('identity.loansQuantity')?.value + 1;
    this.addRequiredValidatorToSelectedLoanNumberControls(newLoanQuantity);

    if (this.loanQuantities && newLoanQuantity < this.loanQuantities) {
      this.resetNotSelectedLoanNumberControls(newLoanQuantity);
    }
    this.loanQuantities = newLoanQuantity;
    this.loanQuantitiesSelected = true;
  }

  private addRequiredValidatorToSelectedLoanNumberControls(loanQuantity: number): void {
    for (let i = 0; i < loanQuantity; i++) {
      const loanNumberSchemaValidator = this.sliceFormsService._getFieldValidator(
        (LOAN_INSURANCE_IDENTITY_FORM.properties as any)[`loanNumber${i + 1}`] as JSONSchema7,
        `loanNumber${i + 1}`,
      );
      this.regForm.get(`identity.loanNumber${i + 1}`)?.setValidators([Validators.required, loanNumberSchemaValidator]);
      this.regForm.get(`identity.loanNumber${i + 1}`)?.updateValueAndValidity();
    }
  }

  private resetNotSelectedLoanNumberControls(loanQuantity: number): void {
    for (let i = this.loanQuantities as number; i > loanQuantity; i--) {
      this.regForm.get(`identity.loanNumber${i}`)?.setValidators([]);
      this.regForm.get(`identity.loanNumber${i}`)?.patchValue('');
      this.regForm.get(`identity.loanNumber${i}`)?.updateValueAndValidity();
    }
  }

  onSubmit(): void {
    if (!this.regForm.get('identity.loansQuantity')?.valid) {
      this._selectError = true;
    }
    if (this.regForm.get('identity')?.valid) {
      this.changeDetectorRef.detectChanges();
      this.myStepper.next();
      this.focusFirstFormField();
    } else {
      this.triggerFormFieldsStatus(this.regForm.get('identity') as FormGroup);
      if (!this.agencyFormComponent) {
        this.focusFirstInvalidFormField();
      } else {
        this.triggerFormFieldsStatus(this.agencyFormComponent.agencyForm as FormGroup);
        const errorsCount = super.getErrorCount(this.regForm.get('identity') as FormGroup);
        if (errorsCount === 1 && !this.regForm.get('identity.agencyRef')?.valid) {
          this.displayMandatoryAgencyMessage = true;
          const agencyQueryControl = this.elementRef.nativeElement.querySelector('app-agency-form');
          agencyQueryControl.focus({ preventScroll: true });
          this.domService.scrollTo(agencyQueryControl);
        } else {
          this.focusFirstInvalidFormField();
        }
      }
    }
  }
}
