import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  Inject,
  forwardRef,
  AfterViewInit,
} from '@angular/core';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG, FORM_COMPONENT } from '../../../../../../tokens';
import { HttpErrorResponse } from '@angular/common/http';
import {
  RECLAMATION_REQUEST_FORM,
  CLIENT_COMPLAINT_CATEGORIES,
} from '@repo/shared/form-schemas/reclamation-connected-client';
import { DOMService } from '../../../../../services/dom.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';

import { ConfigService } from '../../../../../config.service';
import { SliceFormsService } from '../../slice-forms.service';
import { Router } from '@angular/router';
import { LoggerService } from '../../../../../logger/logger.service';
import { Logger } from '../../../../../logger/logger';
import { ReCaptchaV3Service } from 'ng-recaptcha';

export const CONFIRMATION_PAGE_URL = 'confirmation-envoi-reclamation-client';
import { FileUploadComponent } from '../../../../../ui/molecules/form-upload-file/form-upload-file.component';

@Component({
  selector: 'reclamation-connected-customer-request',
  template: `
    <form class="form" [formGroup]="regForm.get('request')" novalidate>
      <div id="request">
        <h2>Message</h2>
        <div formFieldControl class="form-field-control--mega-size">
          <fieldset>
            <legend>Votre demande concerne : *</legend>
            <form-field-select
              formFieldSelect
              [options]="subjectsOptions"
              [formControlName]="'subject'"
              [selectError]="selectError"
            ></form-field-select>
            <form-field-error></form-field-error>
          </fieldset>
        </div>
        <div formFieldControl>
          <textarea
            formControlName="message"
            placeholder="Ecrivez votre message ici..."
            #messageField="formFieldInput"
            formFieldInput
          ></textarea>
          <div formFieldHint>
            {{ messageField.charCountLabel }}
          </div>
          <form-field-error></form-field-error>
        </div>

        <h2>Pièces jointes</h2>
        <p>
          Afin de traiter votre demande le plus rapidement possible, n’hésitez pas à joindre les pièces relatives à
          votre dossier ou la réponse apportée par votre conseiller.
        </p>
        <div formFieldControl class="form-field-control-file-upload">
          <file-upload-form formControlName="files" fileUploadForm></file-upload-form>
          <form-field-error></form-field-error>
        </div>

        <p class="mandatory-field-text">*Champs obligatoires</p>
        <p>
          La politique de protection des données personnelles, incluse dans les Dispositions Générales de Banque est
          disponible sur la page d'accueil du site.
        </p>
        <div class="button-wrapper">
          <cb-cta-btn-link
            class="previous"
            (click)="previous()"
            [button]="{ label: 'Précédent', type: 'multi-step-form-previous' }"
            [buttonWithoutLink]="true"
            [sliceType]="'reclamation_connected_customer_form'"
            [disabled]="_buttonDisabled"
          ></cb-cta-btn-link>
          <cb-cta-btn-link
            class="next"
            (click)="checkRecaptcha()"
            [button]="{ label: 'Envoyer', type: 'multi-step-form-next' }"
            [buttonWithoutLink]="true"
            [sliceType]="'reclamation_connected_customer_form'"
            [disabled]="_buttonDisabled"
          ></cb-cta-btn-link>
          <form-field-error
            class="post-error"
            [class.shown]="_hasPostError"
            message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
          >
          </form-field-error>
        </div>
        <p class="recaptcha-mentions">
          Ce site est protégé par reCAPTCHA. Les
          <a href="https://policies.google.com/privacy">règles de confidentialité</a>
          et les
          <a href="https://policies.google.com/terms">conditions d'utilisation</a>
          de Google s'appliquent.
        </p>
      </div>
    </form>
  `,
  styleUrls: ['../../slice-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: RECLAMATION_REQUEST_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => ReclamationConnectedCustomerRequestComponent),
    },
  ],
})
export class ReclamationConnectedCustomerRequestComponent extends AbstractFormComponent implements AfterViewInit {
  @Input() regForm: FormGroup;

  logger: Logger;

  subjectsOptions = CLIENT_COMPLAINT_CATEGORIES;
  _buttonDisabled = false;
  _hasPostError = false;
  selectError = false;

  @ViewChild(FileUploadComponent, { static: true }) fileUploadComponent;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _reclamationFormValidationSchema: JSONSchema7,
    private readonly sliceFormsService: SliceFormsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    readonly loggerService: LoggerService,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
    private readonly configService: ConfigService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(_reclamationFormValidationSchema, elementRef, domService, changeDetectorRef);
    this.logger = loggerService.get(ReclamationConnectedCustomerRequestComponent.name);
  }

  ngAfterViewInit(): void {
    const requestGroup = this.regForm.get('request') as FormGroup;
    requestGroup.addControl('recaptcha', new FormControl('', Validators.required));
  }

  previous(): void {
    this.myStepper.previous();
  }

  checkRecaptcha(): void {
    this.recaptchaV3Service.execute('entrepriseRequestForm').subscribe(
      token => {
        this.regForm.get('request')?.patchValue({ recaptcha: token });
        this.submitForm();
      },
      error => {
        this.logger.error('Error trying to verify request (reCaptcha v3)', error);
      },
    );
  }

  submitForm(): void {
    if (this.regForm.get('files')) {
      this.regForm.get('files')?.updateValueAndValidity();
    }
    const { valid, value } = this.regForm;
    if (valid) {
      this._buttonDisabled = true;
      this.sliceFormsService.sendReclamationConnectedCustomerForm(value).subscribe(
        () => {
          this.router.navigateByUrl(CONFIRMATION_PAGE_URL);
        },
        (error: HttpErrorResponse) => {
          this.logger.error('Could not submit form data:', error);
          this._buttonDisabled = false;
          this._hasPostError = true;
          this.changeDetectorRef.detectChanges();
        },
      );
    } else {
      this.selectError = !this.regForm.get('request.subject')?.valid;
      this.triggerFormFieldsStatus(this.regForm.get('request') as FormGroup);
      this.changeDetectorRef.detectChanges();
      this.focusFirstInvalidFormField();
    }
  }
}
