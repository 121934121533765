import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
  OnInit,
  Inject,
} from '@angular/core';
import { JSONSchema7 } from 'json-schema';
import { VALIDATION_SCHEMA_CONFIG } from '../../../../../../tokens';
import { ASSISTANCE_CONTACT_FORM, CLIENT_TYPES_OPTIONS } from '@repo/shared/form-schemas/assistance-client';
import { DOMService } from '../../../../../services/dom.service';
import { FormGroup } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';

export const CONFIRMATION_PAGE_URL = 'entreprise/confirmation';

@Component({
  selector: 'assistance-identity',
  template: `
    <form class="form" [formGroup]="regForm" (ngSubmit)="onSubmit()" novalidate>
      <div id="assistance-identity">
        <h2>Identité</h2>
        <div formFieldControl>
          <fieldset>
            <legend>Civilité*</legend>
            <div formFieldRadioGroup class="form-field-control--half-size">
              <form-field-radio name="civilTitle" formControlName="civilTitle" value="Mme">
                <span class="ic ic-woman civil-icon"></span>
                Madame
                <div class="check-icon">
                  <span class="ic-check"></span>
                </div>
              </form-field-radio>
              <form-field-radio
                name="civilTitle"
                formControlName="civilTitle"
                value="M"
                class="form-field-radio--right"
              >
                <span class="ic ic-man civil-icon"></span>
                Monsieur
                <div class="check-icon">
                  <span class="ic-check"></span>
                </div>
              </form-field-radio>
            </div>
            <form-field-error></form-field-error>
          </fieldset>
        </div>
        <div formFieldControl class="form-field-control--half-size">
          <label for="lastName"> Nom* </label>
          <input formControlName="lastName" type="text" name="lastName" placeholder="Dupont" formFieldInput />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size form-field-control--right">
          <label for="firstName">Prénom* </label>
          <input placeholder="Antoine" type="text" name="firstName" formControlName="firstName" formFieldInput />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size">
          <label for="email"> E-mail* </label>
          <input formControlName="email" type="email" name="email" formFieldInput placeholder="exemple@mail.com" />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size form-field-control--right">
          <label for="phone"> Numéro de téléphone* </label>
          <input
            name="phone"
            formControlName="phone"
            type="text"
            formFieldInput
            inputmode="numeric"
            pattern="[0-9]*"
            mask="00 00 00 00 00"
            placeholder="01 23 45 67 89"
          />
          <form-field-error></form-field-error>
        </div>

        <div formFieldControl class="form-field-control--half-size">
          <label for="agencyID"> Agence* </label>
          <input
            formControlName="agencyID"
            name="agencyID"
            formFieldInput
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            mask="00000"
            placeholder="01234"
          />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl class="form-field-control--half-size form-field-control--right">
          <label for="accountNumber"> Compte* </label>
          <input
            formControlName="accountNumber"
            name="accountNumber"
            formFieldInput
            type="text"
            placeholder="012345 + clé"
          />
          <form-field-error></form-field-error>
        </div>
      </div>

      <p class="mandatory-field-text">*Champs obligatoires</p>
      <p>
        La politique de protection des données personnelles, incluse dans les Dispositions Générales de Banque est
        disponible sur la page d'accueil du site.
      </p>

      <div class="button-wrapper">
        <cb-cta-btn-link
          class="button"
          [button]="{ label: 'Suivant', type: 'multi-step-form-next' }"
          [buttonWithoutLink]="true"
          [sliceType]="'assistance_form'"
          [disabled]="_buttonDisabled"
        ></cb-cta-btn-link>
        <form-field-error
          class="post-error"
          [class.shown]="_hasPostError"
          message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
        >
        </form-field-error>
      </div>
    </form>
  `,
  styleUrls: ['../../slice-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: ASSISTANCE_CONTACT_FORM,
    },
  ],
})
export class AssistanceIdentityComponent extends AbstractFormComponent implements OnInit {
  @Input() regForm: FormGroup;

  clientTypesOptions = CLIENT_TYPES_OPTIONS;
  _buttonDisabled = false;
  _hasPostError = false;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _assistanceFormValidationSchema: JSONSchema7,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
  ) {
    super(_assistanceFormValidationSchema, elementRef, domService, changeDetectorRef);
  }

  ngOnInit(): void {
    this.regForm.patchValue({ clientType: 1 });
  }

  onSubmit(): void {
    if (this.regForm.valid) {
      this.changeDetectorRef.detectChanges();
      this.myStepper.next();
      this.focusFirstFormField();
    } else {
      this.triggerFormFieldsStatus(this.regForm);
      this.changeDetectorRef.detectChanges();
      this.focusFirstInvalidFormField();
    }
  }
}
