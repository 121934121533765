import { Component, HostBinding, Input } from '@angular/core';
import { AnalyticsSubPaths } from '../../../../services/tag-commander.service';

@Component({
  selector: 'app-desktop-header-action-button',
  template: `
    <nav role="navigation" aria-label="Menu client">
      <a
        class="link a11y-focus"
        [attr.href]="url"
        [attr.target]="target"
        analyticsOn
        [analyticsLabel]="label"
        [analyticsSubPath]="analyticsSubPath"
        [style.background-color]="hasColorPicker() ? this.backgroundcolorButton : ''"
      >
        <img
          class="icon"
          *ngIf="imgSrc"
          [attr.src]="imgSrc"
          [attr.width]="imgWidth"
          [attr.height]="imgHeight"
          alt="action button icon"
        />
        <span *ngIf="!small" class="label" [style.color]="hasLabelColorPicker() ? this.labelColorButton : ''">{{
          label
        }}</span>
      </a>
    </nav>
  `,
  styleUrls: ['./desktop-header-action-button.component.scss'],
})
export class DesktopHeaderActionButtonComponent {
  @Input() url: string;
  @Input() target: string;
  @Input() imgSrc: string;
  @Input() imgWidth: number;
  @Input() imgHeight: number;
  @Input() label: string;
  @Input() backgroundcolorButton?: string;
  @Input() labelColorButton?: string;

  @HostBinding('class.secondary')
  @Input()
  secondary = false;

  @HostBinding('class.small')
  @Input()
  small = false;

  analyticsSubPath = AnalyticsSubPaths.HeaderRightButtons;

  hasColorPicker(): boolean {
    return this.backgroundcolorButton !== undefined;
  }

  hasLabelColorPicker(): boolean {
    return this.labelColorButton !== undefined;
  }
}
