import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { HeroSlice } from '@repo/shared';
import { ComponentWithData } from '../../../../typings';
import { ResponsiveService } from '../../../services/responsive.service';
import { fromEvent, Subscription } from 'rxjs';
import { FullwidthBackgroundComponent } from '../../common-behaviors/fullwidth-background/fullwidth-background.component';
import { WINDOW } from '../../../services/window.provider';
import { debounceTime, delay } from 'rxjs/operators';
import { CTABtnLinkType } from '../../common-behaviors/button-link/button-link.component';

export interface HeroComponent {
  applyOnceResizeEventReached(): Subscription;

  applyOnceBreakpointEventReached(): Subscription;

  updateView(): void;

  renderOnDesktop(): void;

  renderOnMobile(): void;
}

@Component({
  selector: 'slice-hero',
  template: `
    <ng-container *ngIf="data">
      <div #wrapperRef class="wrapper">
        <cb-fullwidth-background class="background" [image]="data.image"></cb-fullwidth-background>
        <div class="content">
          <h1 class="title" #titleRef>{{ data.title }}</h1>
          <h2 class="subtitle">{{ data.subtitle }}</h2>
          <div *ngIf="data.description" class="description" [innerHTML]="data.description"></div>
          <div class="buttons">
            <ng-container *ngFor="let button of data.buttons; first as isFirst">
              <cb-cta-btn-link
                class="cta-button"
                *ngIf="button"
                type="primary"
                [button]="button"
                [sliceType]="data.type"
              ></cb-cta-btn-link>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceHeroComponent implements HeroComponent, ComponentWithData, OnDestroy, AfterViewInit {
  @Input() data: HeroSlice;

  @ViewChild(FullwidthBackgroundComponent, { read: ElementRef }) backgroundRef: ElementRef | undefined;
  @ViewChild('wrapperRef') wrapperRef: ElementRef | undefined;
  @ViewChild('titleRef') titleRef: ElementRef | undefined;

  public isDesktopMode: boolean;

  private subscription$: Subscription = new Subscription();

  constructor(
    private responsiveService: ResponsiveService,
    private renderer: Renderer2,
    private ngZone: NgZone,
    @Inject(WINDOW) private readonly win: Window,
  ) {}

  ngAfterViewInit(): void {
    if (this.win) {
      this.ngZone.runOutsideAngular(() => {
        this.subscription$.add(this.applyOnceResizeEventReached());
      });
    }

    const waitForCompleteBrowserRendering = 100;
    this.subscription$.add(this.applyOnceBreakpointEventReached(waitForCompleteBrowserRendering));
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  applyOnceResizeEventReached(): Subscription {
    return fromEvent(this.win, 'resize')
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.ngZone.run(() => {
          this.updateView();
        });
      });
  }

  applyOnceBreakpointEventReached(waitingTime: number = 0): Subscription {
    return this.responsiveService
      .isDesktopMatched()
      .pipe(delay(waitingTime))
      .subscribe((isDesktopMode: boolean) => {
        if (this.isDesktopMode !== isDesktopMode) {
          this.isDesktopMode = isDesktopMode;
          this.updateView();
        }
      });
  }

  renderOnDesktop(): void {
    this.renderer.removeStyle(this.wrapperRef?.nativeElement, 'padding-top');
  }

  renderOnMobile(): void {
    if (this.titleRef && this.backgroundRef) {
      const { offsetHeight: backgroundHeight } = this.backgroundRef.nativeElement as HTMLElement;
      const { offsetHeight: titleHeight } = this.titleRef.nativeElement as HTMLElement;
      const newMarginTop: number = backgroundHeight - titleHeight / 2;

      this.renderer.setStyle(this.wrapperRef?.nativeElement, 'padding-top', `${newMarginTop}px`);
    }
  }

  updateView(): void {
    if (this.wrapperRef && this.isDesktopMode !== undefined) {
      this.isDesktopMode ? this.renderOnDesktop() : this.renderOnMobile();
    }
  }
}
