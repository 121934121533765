<form class="form form-width" [formGroup]="regForm.get('claim')" novalidate>
  <div id="claim">
    <h2>Informations</h2>
    <div class="row">
      <div class="column">
        <h3>Identité</h3>
        <div formFieldControl id="civilite">
          <fieldset>
            <legend>Civilité*</legend>
            <div formFieldRadioGroup class="form-field-control--half-size--block">
              <form-field-radio id="civilTitle" name="civilTitle" formControlName="civilTitle" value="Mme">
                <span class="ic ic-woman civil-icon"></span>
                Madame
                <div class="check-icon">
                  <span class="ic-check"></span>
                </div>
              </form-field-radio>
              <form-field-radio
                name="civilTitle"
                formControlName="civilTitle"
                value="M"
                class="form-field-radio--right"
              >
                <span class="ic ic-man civil-icon"></span>
                Monsieur
                <div class="check-icon">
                  <span class="ic-check"></span>
                </div>
              </form-field-radio>
            </div>
            <form-field-error></form-field-error>
          </fieldset>
        </div>
        <div formFieldControl id="lastName" class="form-field-control--half-size--block">
          <label for="lastName"> Nom* </label>
          <input formControlName="lastName" type="text" name="lastName" placeholder="Dupont" formFieldInput />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl id="firstName" class="form-field-control--half-size--block">
          <label for="firstName">Prénom* </label>
          <input placeholder="Antoine" type="text" name="firstName" formControlName="firstName" formFieldInput />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl id="email" class="form-field-control--half-size--block">
          <label for="email"> E-mail* </label>
          <input formControlName="email" type="email" name="email" formFieldInput placeholder="exemple@mail.com" />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl id="birthDate" class="form-field-control--half-size--block">
          <label for="birthDate"> Date de naissance* </label>
          <datepicker formFieldDatepicker formControlName="birthDate" name="birthDate"></datepicker>
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl id="phone" class="form-field-control--half-size--block">
          <label for="phone"> Numéro de téléphone* </label>
          <input
            formControlName="phone"
            name="phone"
            type="text"
            formFieldInput
            inputmode="numeric"
            pattern="[0-9]*"
            mask="00 00 00 00 00"
            placeholder="01 23 45 67 89"
          />
          <form-field-error></form-field-error>
        </div>
      </div>
      <div class="column">
        <h3>Adresse</h3>
        <div formFieldControl id="street" class="form-field-control--half-size--block">
          <label for="street">Rue* </label>
          <input placeholder="25 rue Saint Louis" type="text" formControlName="street" name="street" formFieldInput />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl id="city" class="form-field-control--half-size--block">
          <label for="city">Ville* </label>
          <input placeholder="Paris" type="text" name="city" formControlName="city" formFieldInput />
          <form-field-error></form-field-error>
        </div>
        <div formFieldControl id="postalCode" class="form-field-control--half-size--block">
          <label for="postalCode">Code postal* </label>
          <input
            placeholder="75011"
            formControlName="postalCode"
            name="postalCode"
            formFieldInput
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            mask="00000"
          />
          <form-field-error></form-field-error>
        </div>
        <div
          formFieldControl
          id="clientCategory"
          class="form-field-control--half-size--block"
          *ngIf="regForm.get('identity.clientFunction')?.value === 0"
        >
          <label for="clientCategory"> Je suis un client : * </label>
          <form-field-select
            formFieldSelect
            name="clientCategory"
            [options]="clientCategoryOptions"
            [placeholder]="placeholder_client_type"
            [formControlName]="'clientCategory'"
            [selectError]="selectError"
            [(ngModel)]="selectedClientCategoryValue"
          ></form-field-select>
          <form-field-error></form-field-error>
        </div>
        <div
          formFieldControl
          id="businessName"
          class="form-field-control--half-size--block"
          [style.visibility]="cssDisplayForCompanyName"
        >
          <label for="businessName">Raison sociale (si personne morale) </label>
          <input
            placeholder="MaSociété SAS"
            type="text"
            formControlName="businessName"
            name="businessName"
            formFieldInput
          />
          <form-field-error></form-field-error>
        </div>
      </div>
    </div>
    <div [style.display]="cssDisplayForAgencyForm">
      <h2>Agence LCL</h2>
      <div class="agencies-search-box">
        <div>
          <div formFieldControl class="form-field-control--half-size">
            <app-agency-form
              (optionSelected)="searchNearAgencies($event)"
              [isChildForm]="true"
              [displayNoAgencyMessage]="this.showSearchAgenciesResults && this.agencies.length === 0"
            >
            </app-agency-form>
          </div>
        </div>
        <div
          *ngIf="showSearchAgenciesResults && agencies.length > 0 && !selectedAgency"
          formFieldControl
          class="agencies-result-container"
        >
          <label>Sélectionnez une agence dans la liste de résultats.</label>
          <div *ngIf="displayMandatoryAgencyMessage" class="agency-mandatory">Sélection obligatoire.</div>
          <div *ngIf="agencies.length === 0" class="agency-count">
            Aucune agence LCL trouvée. Veuillez refaire une recherche
          </div>
          <div class="agencies-container">
            <div
              *ngFor="let agency of agencies; let agencyIndex = index; let odd = odd"
              class="agency"
              [ngClass]="{ 'agency-odd': odd }"
              (click)="updateAgencyData(agency)"
            >
              <div class="agency-info-name">{{ agency.name }}</div>
              <div>{{ agency.address }} - {{ agency.postalCode }} {{ agency.town }}</div>
            </div>
          </div>
        </div>
        <div formFieldControl class="hide-item">
          <label
            >Agence
            <input formControlName="agencyRef" formFieldInput type="text" />
          </label>
        </div>
      </div>
      <h2>Référence compte</h2>
      <h3>Champs non obligatoires mais recommandés</h3>
      <div formFieldControl id="agencyID" class="form-field-control--half-size">
        <label for="agencyID">Indicatif </label>
        <input
          placeholder="01234"
          formControlName="agencyID"
          name="agencyID"
          formFieldInput
          type="text"
          inputmode="numeric"
          pattern="[0-9]*"
          mask="00000"
        />
        <form-field-error></form-field-error>
      </div>
      <div formFieldControl id="accountNumber" class="form-field-control--half-size form-field-control--right">
        <label for="accountNumber">Compte </label>
        <input
          placeholder="012345 + clé"
          name="accountNumber"
          formControlName="accountNumber"
          formFieldInput
          type="text"
        />
        <form-field-error></form-field-error>
      </div>
    </div>
    <div *ngIf="regForm.get('identity.clientFunction')?.value === 2">
      <div formFieldControl id="clientType" class="form-field-control--half-size subject-form-field-control">
        <label>
          <ng-select formFieldInput formControlName="clientType" [clearable]="false" [searchable]="false">
            <ng-option *ngFor="let option of clientTypeOptions; index as i" [value]="i">{{ option }} </ng-option>
          </ng-select>
        </label>
      </div>
      <h2>Identité du client pour lequel vous intervenez</h2>
      <div class="row">
        <div class="column">
          <h3>Identité</h3>
          <div formFieldControl id="civilTitleClient">
            <fieldset>
              <legend>Civilité*</legend>
              <div formFieldRadioGroup class="form-field-control--half-size--block">
                <form-field-radio name="civilTitleClient" formControlName="civilTitleClient" value="Mme">
                  <span class="ic ic-woman civil-icon"></span>
                  Madame
                  <div class="check-icon">
                    <span class="ic-check"></span>
                  </div>
                </form-field-radio>
                <form-field-radio
                  name="civilTitleClient"
                  formControlName="civilTitleClient"
                  value="M"
                  class="form-field-radio--right"
                >
                  <span class="ic ic-man civil-icon"></span>
                  Monsieur
                  <div class="check-icon">
                    <span class="ic-check"></span>
                  </div>
                </form-field-radio>
              </div>
              <form-field-error></form-field-error>
            </fieldset>
          </div>
          <div formFieldControl id="lastNameClient" class="form-field-control--half-size--block">
            <label for="lastNameClient"> Nom* </label>
            <input
              formControlName="lastNameClient"
              name="lastNameClient"
              type="text"
              placeholder="Dupont"
              formFieldInput
            />
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="firstNameClient" class="form-field-control--half-size--block">
            <label for="firstNameClient">Prénom* </label>
            <input
              placeholder="Antoine"
              type="text"
              name="firstNameClient"
              formControlName="firstNameClient"
              formFieldInput
            />
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="emailClient" class="form-field-control--half-size--block">
            <label for="emailClient"> E-mail </label>
            <input
              formControlName="emailClient"
              name="emailClient"
              type="email"
              formFieldInput
              placeholder="exemple@mail.com"
            />
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="birthDateClient" class="form-field-control--half-size--block">
            <label for="birthDateClient"> Date de naissance </label>
            <datepicker [formControlName]="'birthDateClient'" name="birthDateClient"></datepicker>
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="phoneClient" class="form-field-control--half-size--block">
            <label for="phoneClient"> Numéro de téléphone </label>
            <input
              formControlName="phoneClient"
              name="phoneClient"
              type="text"
              formFieldInput
              inputmode="numeric"
              pattern="[0-9]*"
              mask="00 00 00 00 00"
              placeholder="01 23 45 67 89"
            />
            <form-field-error></form-field-error>
          </div>
          <div
            formFieldControl
            id="clientCategory"
            class="form-field-control--half-size--block"
            *ngIf="regForm.get('identity.clientFunction')?.value === 2"
          >
            <fieldset>
              <legend>Je suis un client : *</legend>
              <form-field-select
                formFieldSelect
                [options]="clientCategoryOptions"
                [formControlName]="'clientCategory'"
                [selectError]="selectError"
              ></form-field-select>
              <form-field-error></form-field-error>
            </fieldset>
          </div>
        </div>
        <div class="column">
          <h3>Adresse</h3>
          <div formFieldControl id="streetClient" class="form-field-control--half-size--block">
            <label for="streetClient">Rue* </label>
            <input
              placeholder="25 rue Saint Louis"
              name="streetClient"
              type="text"
              formControlName="streetClient"
              formFieldInput
            />
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="cityClient" class="form-field-control--half-size--block">
            <label for="cityClient">Ville* </label>
            <input placeholder="Paris" type="text" name="cityClient" formControlName="cityClient" formFieldInput />
            <form-field-error></form-field-error>
          </div>
          <div formFieldControl id="postalCodeClient" class="form-field-control--half-size--block">
            <label for="postalCodeClient">Code postal* </label>
            <input
              placeholder="75011"
              name="postalCodeClient"
              formControlName="postalCodeClient"
              formFieldInput
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              mask="00000"
            />
            <form-field-error></form-field-error>
          </div>
        </div>
      </div>
    </div>
    <p class="mandatory-field-text">*Champs obligatoires</p>
    <p>
      La politique de protection des données personnelles, incluse dans les Dispositions Générales de Banque est
      disponible sur la page d'accueil du site.
    </p>
  </div>
  <div class="button-wrapper">
    <cb-cta-btn-link
      class="previous"
      (click)="previous()"
      [button]="{ label: 'Précédent', type: 'multi-step-form-previous' }"
      [buttonWithoutLink]="true"
      [sliceType]="'reclamation_form'"
      [disabled]="_buttonDisabled"
    ></cb-cta-btn-link>
    <cb-cta-btn-link
      class="next"
      (click)="onSubmit()"
      [button]="{ label: 'Suivant', type: 'multi-step-form-next' }"
      [buttonWithoutLink]="true"
      [sliceType]="'reclamation_form'"
      [disabled]="_buttonDisabled"
    ></cb-cta-btn-link>
    <form-field-error
      class="post-error"
      [class.shown]="_hasPostError"
      message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
    >
    </form-field-error>
  </div>
</form>
