import {
  Component,
  Input,
  OnDestroy,
  Inject,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ComponentWithData } from '../../../../typings';
import { SimulatorSlice, SimulatorType } from '@repo/shared';
import { iframeResizer, IFrameComponent } from 'iframe-resizer';
import { WINDOW } from '../../../services/window.provider';
import { ConfigService } from '../../../config.service';
import { DOMScriptService } from '../../../services/dom-script.service';
import { DOMService } from '../../../services/dom.service';
import { StateService } from '../../../services/state.service';

@Component({
  selector: 'slice-simulator',
  template: `
    <ng-container *ngIf="data">
      <div class="iframe-wrapper">
        <div *ngIf="!isNexecur">
          <div *ngIf="!loaded" class="loader desktop-only">Loading...</div>
          <iframe
            [id]="iframeId"
            [src]="data.url | trustResourceUrl"
            [class.desktop-only]="data.mobileIsDifferent"
            [attr.width]="iframeWidth"
            frameborder="0"
            [attr.height]="data.height"
            (load)="iframeLoaded()"
          >
          </iframe>
        </div>
        <div *ngIf="isNexecur">
          <div id="iframe-nexecur-container" #iframeNexecurContainer></div>
          <label id="iframe-nexecur-origin" hidden>{{ iframeNexecurOrigin }}</label>
        </div>
      </div>
      <div
        *ngIf="data.mobileIsDifferent"
        class="mobile-simulator-unavailable-message mobile-only"
        [innerHTML]="data.description"
      ></div>
    </ng-container>
  `,
  styleUrls: ['./slice-simulator.component.scss'],
})
export class SliceSimulatorComponent implements OnInit, AfterViewInit, ComponentWithData, OnDestroy {
  @Input() data: SimulatorSlice;
  @Input() sliceIndex: number;

  loaded = false;
  nni = false;

  @ViewChild('iframeNexecurContainer', { static: false }) divNexecur: ElementRef<HTMLDivElement>;
  isNexecur = false;
  iframeNexecurOrigin: string;
  nexecurScriptContent: any;

  private iFrameComponent: IFrameComponent;

  private readonly DOMAIN_ORIGIN_SIMULATOR_RCT = 'https://rct1.digisimu-lcl.ca-cf.group.gca';
  private readonly DOMAIN_ORIGIN_SIMULATOR_PRD = 'https://digisimu-lcl.lcl.fr';
  private readonly VALID_ORIGINS = [this.DOMAIN_ORIGIN_SIMULATOR_RCT, this.DOMAIN_ORIGIN_SIMULATOR_PRD];

  readonly IFRAME_DEFAULT_WIDTH = '100%';

  pageUid: string;

  get iframeWidth(): string {
    return this.IFRAME_DEFAULT_WIDTH;
  }

  get iframeId(): string {
    return 'slice' + this.sliceIndex;
  }

  constructor(
    @Inject(WINDOW) private readonly win: Window,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _elementRef: ElementRef,
    private readonly _domService: DOMService,
    private readonly configService: ConfigService,
    private readonly domScriptService: DOMScriptService,
    private readonly stateService: StateService,
  ) {}

  ngOnInit(): void {
    this.pageUid = this.stateService.get().pageUid || ' ';
    this.isNexecur = this.data.simulatorType === SimulatorType.Nexecur;
    this.iframeNexecurOrigin = this.configService.get<string>('HOST');
  }

  ngAfterViewInit(): void {
    if (!this.win) {
      return;
    }

    this.addMessageListener();
    if (this.isNexecur) {
      this.nexecurScriptContent = require('./nexecur-script.js');
      this.domScriptService.updateInlineScript('nexecurScript', this.nexecurScriptContent);
    }
  }

  addMessageListener(): void {
    if (this.win.addEventListener) {
      this.win.addEventListener('message', this.receiveMessage, true);
    }
  }

  removeMessageListener(): void {
    if (this.win.removeEventListener) {
      this.win.removeEventListener('message', this.receiveMessage, true);
    }
  }

  receiveMessage = (_event: MessageEvent): void => {
    if (this.VALID_ORIGINS.includes(_event.origin)) {
      const eventData = this.safeParseJSON(_event.data);
      if (!eventData) return;

      if (eventData.messageType === 'IFrameResize') this.handleIframeResizeMessage(eventData);
    }
  };

  safeParseJSON(data: string) {
    try {
      return JSON.parse(data);
    } catch (error) {
      console.error('Failed to parse JSON data:', error);
      return null;
    }
  }

  handleIframeResizeMessage(eventData: any): void {
    const { productorFrameHeight, retourTop } = eventData;

    if (productorFrameHeight) {
      this.data.height = productorFrameHeight;
      this.nni = true;
    }
    if (retourTop) {
      const iframeElement = this._elementRef.nativeElement.parentNode;
      this._domService.scrollTo(iframeElement);
    }

    this.removeMessageListener();
    this.changeDetectorRef.detectChanges();
    return;
  }

  iframeLoaded(): void {
    this.loaded = true;
    if (!this.iFrameComponent && this.data.simulatorType === SimulatorType.Harvest) {
      this.iFrameComponent = iframeResizer(
        {
          checkOrigin: false,
          heightCalculationMethod: 'documentElementOffset',
          log: false,
        },
        '#' + this.iframeId,
      )[0];
    }
  }

  ngOnDestroy(): void {
    if (this.iFrameComponent) {
      this.iFrameComponent.iFrameResizer.close();
    }
  }
}
