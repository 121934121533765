import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Link } from '@repo/shared';
import { AbstractAnalyticsCTADirective } from '../../../analytics/abstract-analytics-cta-component';

export enum CTABtnLinkType {
  Primary = 'primary',
  Secondary = 'contact',
  Light = 'light',
  LandingPage = 'landing-page',
  MultiStepFormNext = 'multi-step-form-next',
  MultiStepFormPrevious = 'multi-step-form-previous',
  SmartAppBannerDownload = 'smart-app-banner-download',
  MobileViewAddFile = 'mobile-view-add-file',
  PushBanner = 'push-banner',
  LifeMoment = 'life-moment',
}

export const NON_ACTIVE_ANALYTICS_BUTTON_LINK_ON_SLICE_TYPE = ['super_hero', 'offer_block'];

@Component({
  selector: 'cb-cta-btn-link',
  template: `
    <a
      *ngIf="!buttonWithoutLink"
      class="cta-btn-link a11y-focus"
      [ngClass]="className"
      [attr.href]="button?.url"
      [attr.target]="target"
      analyticsOn
      [analyticsLabel]="analyticsLabel"
      [analyticsActive]="analyticsActive"
      [analyticsSubPath]="analyticsSubPath"
      [style.background-color]="buttonBgColor"
      (click)="linkClicked.emit($event)"
    >
      <div class="label">
        <span [style]="labelStyles" class="label-text">{{ button?.label }}</span>
        <div *ngIf="ctaBtnColor" class="label-icon" [ngStyle]="{ 'background-color': ctaBtnColor }"></div>
      </div>
    </a>
    <button
      *ngIf="buttonWithoutLink"
      class="cta-btn-link a11y-focus"
      [ngClass]="className"
      analyticsOn
      [analyticsLabel]="analyticsLabel"
      [analyticsActive]="analyticsActive"
      [analyticsSubPath]="analyticsSubPath"
      [style.background-color]="disabledButtonColor()"
      [disabled]="disabled"
    >
      <div class="label">
        <span [style]="labelStyles" class="label-text">{{ button?.label }}</span>
        <div *ngIf="ctaBtnColor" class="label-icon" [ngStyle]="{ 'background-color': ctaBtnColor }"></div>
      </div>
    </button>
  `,
  styleUrls: ['./button-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CTAButtonLinkComponent extends AbstractAnalyticsCTADirective implements OnInit {
  static CLASSNAME_PREFIX = 'cta-btn-link--';

  @Input() button: Link;
  @Input() buttonWithoutLink = false;
  @Input() disabled = false;
  @Input() type: CTABtnLinkType;
  @Input() labelStyles: { [key: string]: string } = {};

  @Input() buttonBgColor?: string;

  @Output() linkClicked = new EventEmitter<Event>();

  ctaBtnColor: string;

  static computeClassName(type?: CTABtnLinkType): string {
    switch (type) {
      case CTABtnLinkType.Primary:
      case CTABtnLinkType.Secondary:
      case CTABtnLinkType.Light:
      case CTABtnLinkType.LandingPage:
      case CTABtnLinkType.MultiStepFormNext:
      case CTABtnLinkType.MultiStepFormPrevious:
      case CTABtnLinkType.SmartAppBannerDownload:
      case CTABtnLinkType.MobileViewAddFile:
      case CTABtnLinkType.PushBanner:
      case CTABtnLinkType.LifeMoment:
        return CTAButtonLinkComponent.CLASSNAME_PREFIX + type;
      default:
        return CTAButtonLinkComponent.CLASSNAME_PREFIX + CTABtnLinkType.Primary;
    }
  }

  get CTA(): Link {
    return this.button;
  }

  get analyticsActive(): boolean {
    return !!this.sliceType && !NON_ACTIVE_ANALYTICS_BUTTON_LINK_ON_SLICE_TYPE.includes(this.sliceType);
  }

  get className(): string {
    switch (this.type) {
      case CTABtnLinkType.Primary:
      case CTABtnLinkType.Secondary:
      case CTABtnLinkType.Light:
      case CTABtnLinkType.LandingPage:
      case CTABtnLinkType.MultiStepFormNext:
      case CTABtnLinkType.MultiStepFormPrevious:
      case CTABtnLinkType.SmartAppBannerDownload:
      case CTABtnLinkType.MobileViewAddFile:
      case CTABtnLinkType.PushBanner:
      case CTABtnLinkType.LifeMoment:
        return CTAButtonLinkComponent.CLASSNAME_PREFIX + this.type;
      default:
        return CTAButtonLinkComponent.computeClassName(this.button && (this.button.type as CTABtnLinkType));
    }
  }

  constructor() {
    super();
  }

  ngOnInit() {
    this.ctaBtnColor = this.labelStyles.btnColor || '#ffffff';
  }

  disabledButtonColor() {
    if (this.disabled) {
      return 'grey';
    }
  }
}
